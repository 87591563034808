<template>
  <!-- autoplay -->
  <a-carousel :after-change="onChange">
    <div v-for="item in bannerArr" class="index-banner-content" :style="`background-color:red;`">
      <img class="banner-img" :src="item.pictureUrl" alt="">
      <div class="max-section section banner-word">
        <div class="title fs-50 c0 fw-500 mb-20">{{ item.title }}</div>
        <div class="des fs-22 c9">{{ item.description }}</div>
      </div>
    </div>
  </a-carousel>
</template>

<script lang="ts" setup>
defineProps<{bannerArr:any[]}>()
const onChange = (current: number) => {
  console.log(current);
};
</script>

<style scoped lang="less">
.banner-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.index-banner-content {
  // background-image: url('../../../assets/image/index-banner1.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  height: 848px;
  position: relative;

  .banner-word {
    position: absolute;
    bottom: 220px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

.ant-carousel :deep(.slick-dots) {
  justify-content: flex-end;

  li {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    overflow: hidden;

    button,
    button:hover {
      width: 100%;
      height: 100%;
      opacity: 1;
    }

    &.slick-active {
      width: 60px;
      border-radius: 10px;

      button {
        background-color: #00B96B;
      }
    }
  }
}
.ant-carousel :deep(.slick-dots-bottom) {
  bottom: 220px;
  width: 1580px;
  margin: 0px auto;
}
@media screen and (max-width: 1580px) {
  .index-banner-content{
    height: 600px;
  }
  .ant-carousel :deep(.slick-dots-bottom) {
    bottom: 100px;
    width: 92%;
    margin: 0px auto;
  }
}

@media screen and (max-width: 1024px) {
  .index-banner-content{
    height: 500px;
  }
}
@media screen and (max-width: 640px) {
  .index-banner-content{
    height: 400px;
    .banner-word{
      bottom: 180px;
    }
  }
}
@media screen and (max-width: 1580px) {
  .index-banner-content .banner-word{
    bottom: 110px;
  }
}
</style>
