<script setup lang="ts">
import {ref, shallowRef, computed, watch, watchPostEffect} from 'vue'
import {useRouter} from 'vue-router'

/*
  宽度固定，图片等比例缩放；使用JS获取每张图片宽度和高度，结合 `relative` 和 `absolute` 定位
  计算每个图片的位置 `top`，`left`，保证每张新的图片都追加在当前高度最小的那列末尾
*/
interface Image {
  src: string // 图片地址
  title?: string // 图片名称
}

interface Props {
  images: Image[] // 图片数组
  columnCount?: number // 要划分的列数
  columnGap?: number // 各列之间的间隙，单位px
  width?: string | number // 瀑布流区域的总宽度
  borderRadius?: number // 瀑布流区域和图片圆角，单位px
  backgroundColor?: string // 瀑布流区域背景填充色
}

const props = withDefaults(defineProps<Props>(), {
  images: () => [],
  columnCount: 3,
  columnGap: 20,
  width: '100%',
  borderRadius: 20,
  backgroundColor: '#fafafa'
})
const imagesProperty = ref<any[]>([])
const preColumnHeight = ref<number[]>(Array(props.columnCount).fill(0)) // 每列的高度
const waterfall = shallowRef() // ref() 的浅层作用形式
const imageWidth = ref()
const totalWidth = computed(() => {
  if (typeof props.width === 'number') {
    return props.width + 'px'
  } else {
    return props.width
  }
})
const height = computed(() => {
  return Math.max(...preColumnHeight.value) + props.columnGap
})
const len = computed(() => {
  return props.images.length
})
const loaded = ref(Array(len.value)) // 图片是否加载完成
const rerender = ref(false)
watch(
    () => [props.columnCount, props.columnGap, props.width],
    () => {
      rerender.value = true
      preColumnHeight.value = Array(props.columnCount).fill(0)
      onPreload()
    },
    {
      deep: true, // 强制转成深层侦听器
      flush: 'post' // 在侦听器回调中访问被 Vue 更新之后的 DOM
    }
)
watchPostEffect(() => {
  if (props.images.length) {
    onPreload()
  }
})

async function onPreload() { // 计算图片宽高和位置（top，left）
                             // 计算每列的图片宽度
                             // imageWidth.value = (waterfall.value.offsetWidth - (props.columnCount + 1) * props.columnGap) / props.columnCount
  imageWidth.value = (waterfall.value.offsetWidth - props.columnGap * (props.columnCount - 1)) / props.columnCount;
  imagesProperty.value.splice(0)
  for (let i = 0; i < len.value; i++) {
    await loadImage(props.images[i].coverUrls, i)
  }
}

function loadImage(url: string, n: number) {
  return new Promise((resolve) => {
    const image = new Image()
    image.src = url
    image.onload = function () { // 图片加载完成时执行，此时可通过image.width和image.height获取到图片原始宽高
      if (!rerender.value) {
        loaded.value[n] = false
      }
      var height = image.height / (image.width / imageWidth.value)
      imagesProperty.value[n] = { // 存储图片宽高和位置信息
        width: imageWidth.value,
        height: height > 800 ? 800 : height,
        ...getPosition(n, height)
      }
      resolve('load')
    }
  })
}

function getPosition(i: number, height: number) {
  const columnWidth = imageWidth.value;
  const leftGap = props.columnGap;

  if (i < props.columnCount) {
    preColumnHeight.value[i] = props.columnGap + height;
    return {
      top: props.columnGap,
      left: (columnWidth + leftGap) * i
    };
  } else {
    const minColHeight = Math.min(...preColumnHeight.value);
    let minColIndex = preColumnHeight.value.indexOf(minColHeight);
    preColumnHeight.value[minColIndex] += props.columnGap + height;
    return {
      top: minColHeight + props.columnGap,
      left: (columnWidth + leftGap) * minColIndex
    };
  }
}

function onLoaded(index: number) {
  loaded.value[index] = true
}

const router = useRouter()

const goDetail = (row: any) => {
  if (row.linkUrl) {
    window.open(row.linkUrl)
  } else {
    window.open("/details?id=" + row.id)
    // router.push({
    //   path: '/details',
    //   query: { id: row.id }
    // })
  }
}
</script>
<template>
  <div class="m-waterfall" ref="waterfall"
       :style="`--borderRadius: ${borderRadius}px; width: ${totalWidth}; height: ${height}px;`"
       v-if="images && images.length > 0">
    <div class="m-image"
         :style="`width: ${property?.width}px; height: ${property?.height}px; top: ${property && property?.top}px; left: ${property && property?.left}px;`"
         size="small" indicator="dynamic-circle" v-for="(property, index) in imagesProperty" :key="index">
      <a-spin :spinning="!loaded[index]">
        <img class="u-image" :src="images[index]?.coverUrls" :alt="images[index]?.name" @load="onLoaded(index)"/>
      </a-spin>
      <div class="mask" @click="goDetail(images[index])">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1"
             width="40" height="40" viewBox="0 0 40 40">
          <g>
            <g>
              <g style="opacity:0.5;">
                <ellipse cx="20" cy="20" rx="19.5" ry="19.5" fill-opacity="0" stroke-opacity="0.5" stroke="#FFFFFF"
                         fill="none" stroke-width="1"/>
              </g>
              <g>
                <g>
                  <g></g>
                </g>
                <g>
                  <g>
                    <path
                        d="M28.31855354127884,19.082909967803957C28.167853541278838,18.819179967803954,25.19125354127884,12.979259967803955,19.42673354127884,12.979259967803955C13.66217354127884,12.979259967803955,10.685706541278838,18.819179967803954,10.534998541278839,19.082909967803957C10.308938441278839,19.497359967803956,10.308938441278839,20.024839967803956,10.534998541278839,20.439279967803955C10.685706541278838,20.703029967803957,13.66218354127884,26.542959967803956,19.42673354127884,26.542959967803956C25.19125354127884,26.542959967803956,28.167753541278838,20.703029967803957,28.31845354127884,20.439279967803955C28.50685354127884,20.024839967803956,28.50685354127884,19.497359967803956,28.31845354127884,19.082909967803957C28.31845354127884,19.082909967803957,28.31855354127884,19.082909967803957,28.31855354127884,19.082909967803957ZM19.42673354127884,25.035859967803955C14.528753541278839,25.035859967803955,11.891363541278839,19.761109967803954,11.891363541278839,19.761109967803954C11.891363541278839,19.761109967803954,14.528753541278839,14.486339967803955,19.42673354127884,14.486339967803955C24.324753541278838,14.486339967803955,26.962153541278838,19.761109967803954,26.962153541278838,19.761109967803954C26.962153541278838,19.761109967803954,24.324753541278838,25.035859967803955,19.42673354127884,25.035859967803955C19.42673354127884,25.035859967803955,19.42673354127884,25.035859967803955,19.42673354127884,25.035859967803955Z"
                        fill="#FFFFFF" fill-opacity="1"/>
                  </g>
                  <g>
                    <path
                        d="M19.426823199481966,16.74692964553833C17.768983199481966,16.74692964553833,16.412603199481964,18.10327964553833,16.412603199481964,19.76108964553833C16.412603199481964,21.41886964553833,17.768983199481966,22.77524964553833,19.426823199481966,22.77524964553833C21.084543199481963,22.77524964553833,22.440923199481965,21.41886964553833,22.440923199481965,19.76108964553833C22.440923199481965,18.10327964553833,21.084543199481963,16.74692964553833,19.426823199481966,16.74692964553833C19.426823199481966,16.74692964553833,19.426823199481966,16.74692964553833,19.426823199481966,16.74692964553833ZM19.426823199481966,21.26813964553833C18.597903199481962,21.26813964553833,17.919683199481963,20.589979645538328,17.919683199481963,19.76108964553833C17.919683199481963,18.93219964553833,18.597903199481962,18.25400964553833,19.426823199481966,18.25400964553833C20.255683199481965,18.25400964553833,20.933843199481963,18.93219964553833,20.933843199481963,19.76108964553833C20.933843199481963,20.589979645538328,20.255683199481965,21.26813964553833,19.426823199481966,21.26813964553833C19.426823199481966,21.26813964553833,19.426823199481966,21.26813964553833,19.426823199481966,21.26813964553833Z"
                        fill="#FFFFFF" fill-opacity="1"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.m-waterfall {
  position: relative;
  border-radius: var(--borderRadius);
  margin: 0px auto;
  transition: all 0.3s ease-in-out;
  /* 可选：平滑过渡效果 */

  .m-image {
    position: absolute;
    border-radius: var(--borderRadius);
    overflow: hidden;

    .u-image {
      width: 100%;
      height: 100%;
      // border-radius: var(--borderRadius);
      display: inline-block;
      vertical-align: bottom;
      max-height: 800px;
    }

    .mask {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 1;
      opacity: 0;
      background: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      color: #fff;
      transition: opacity 0.5s ease;
      cursor: pointer;
    }

    &:hover {
      .mask {
        opacity: 1
      }
    }
  }
}
</style>