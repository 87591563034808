import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'
import request from '@/utils/request';
import { useRoute } from 'vue-router'
const route = useRoute()

// 为 store state 声明类型
export interface State {
  // 用户信息
  userInfo: any,
  categoryList: any,
  myBalance: any,
  categoryType: any
}

// 定义 injection key
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {
    userInfo: localStorage.getItem("userInfo") !== null ? JSON.parse(localStorage.getItem("userInfo")!) : null,
    categoryList: localStorage.getItem("categoryList") !== null ? JSON.parse(localStorage.getItem("categoryList")!) : null,
    myBalance: localStorage.getItem("myBalance") !== null ? JSON.parse(localStorage.getItem("myBalance")!) : null,
    categoryType: localStorage.getItem("categoryType") || {
      value: '',
      name: ''
    }
  },
  mutations: {
    // 设置用户信息
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    // 存分类列表信息
    setCategory(state, categoryList) {
      state.categoryList = categoryList
      localStorage.setItem("categoryList", JSON.stringify(categoryList))
    },
    setBalance(state, val) {
      state.myBalance = val
    },
    setCategoryType(state, val) {
      state.categoryType = val
      localStorage.setItem("categoryType", val)
    },
  },
  actions: {
    // 异步操作
    fetchUserInfo({ commit, state }) {
      request.get("/user/getUserInfo").then((res) => {
        if (res && res.code === 200) {
          commit("setUserInfo", res.result)
          localStorage.setItem("userInfo", JSON.stringify(res.result))
        }
      })
    },
    // 获取用户的余额
    getBalance({ commit, state }) {
      request.get("/order/getUserIncome").then((res) => {
        if (res && res.code === 200) {
          commit("setBalance", res.result)
          localStorage.setItem("myBalance", JSON.stringify(res.result))
        }
      })
    }
  }
})

