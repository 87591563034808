import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/home/index.vue";
import Layout from '@/layout/index.vue'
import MobileWarningModal from '@/components/MobileWarningModal.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
    },
    component: () =>
      import("@/views/login/index.vue"),
  },
  {
    path: "/alipay",
    name: "alipay",
    meta: {
      title: "支付宝支付",
    },
    component: () =>
      import("../views/details/alipay/index.vue"),
  },
  {
    path: "/",
    name: "home",
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: "home",
        component: HomeView,
        meta: {
          title: "首页",
        },
      },
      {
        path: "/recharge",
        name: "recharge",
        component: () =>
          import("../views/recharge/index.vue"),
        meta: {
          title: "充值积分",
        },
      },
      {
        path: "/recharge/price",
        name: "rechargePrice",
        meta: {
          title: "充值",
        },
        component: () =>
          import("../views/recharge/price/index.vue"),
      },
      {
        path: "/uploadInt",
        name: "uploadInt",
        meta: {
          title: "上传赚钱",
        },
        component: () =>
          import("../views/uploadInt/index.vue"),
      },
      {
        path: "/details",
        name: "details",
        meta: {
          title: "作品详情",
        },
        component: () =>
          import("../views/details/index.vue"),
      },

      {
        path: "/publish",
        name: "publish",
        meta: {
          title: "发布作品",
          keepAlive: true, // 组件需要缓存
        },
        component: () =>
          import("../views/publish/index.vue"),
      },
      {
        path: "/source",
        name: "source",
        meta: {
          title: "来源素材",
        },
        component: () =>
          import("../views/source/index.vue"),
      },
      {
        path: "/filtering",
        name: "filtering",
        meta: {
          title: "来源素材",
        },
        component: () =>
          import("../views/filtering/index.vue"),
      },
      {
        path: "/my",
        name: "my",
        component: () =>
          import("../views/my/index.vue"),
        children: [
          {
            path: "/my/home",
            name: "myHome",
            component: () => import("../views/my/home/index.vue"),
          },
          {
            path: "/my/nojoin",
            name: "myNojoin",
            component: () => import("../views/my/nojoin/index.vue"),
          },
          {
            path: "/my/works",
            name: "myWorks",
            component: () => import("../views/my/works/index.vue"),
          },
          {
            path: "/my/earnings",
            name: "myEarnings",
            component: () => import("../views/my/earnings/index.vue"),
          },
          {
            path: "/my/payment",
            name: "myPayment",
            component: () => import("../views/my/payment/index.vue"),
          },
          {
            path: "/my/download",
            name: "myDownload",
            meta: {
              title: "我的下载",
              requiresAuth: true
            },
            component: () => import("../views/my/download/index.vue"),
          },
          {
            path: "/my/collect",
            name: "myCollect",
            component: () => import("../views/my/collect/index.vue"),
          },
          {
            path: "/my/setting",
            name: "mySetting",
            component: () => import("../views/my/setting/index.vue"),
          },
          // {
          //   path: "/my/upload",
          //   name: "myUpload",
          //   component: () => import("../views/my/upload/index.vue"),
          // }
        ]
      },
      {
        path: "/search",
        name: "search",
        meta: {
          title: "搜索结果",
        },
        component: () =>
          import("../views/search/index.vue"),
      },
      {
        path: "/search/mobile",
        name: "searchMobile",
        meta: {
          title: "搜索结果",
        },
        component: () =>
          import("../views/search/mobile.vue"),
      },
      {
        path: "/type",
        name: "type",
        meta: {
          title: "分类",
        },
        component: () =>
          import("../views/type/index.vue"),
      },
      {
        path: "/refund",
        name: "refund",
        meta: {
          title: "申请退款",
        },
        component: () =>
          import("../views/refund/index.vue"),
      },
      {
        path: "/about",
        name: "about",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
      },
      {
        path: "/dev/components",
        name: "components",
        component: () =>
          import("../views/dev/ComponentsView.vue"),
      },
      {
        path: "/recommend",
        name: "recommend",
        meta: {
          title: "精选品牌厂商",
        },
        component: () =>
          import("../views/recommend/index.vue"),
      },
      {
        path: "/latest",
        name: "latest",
        meta: {
          title: "最新推荐",
        },
        component: () =>
          import("../views/latest/index.vue"),
      },
      {
        path: "/stylist",
        name: "stylist",
        component: () =>
          import("../views/stylist/index.vue"),
      },
      {
        path: "/recharge/payment",
        name: "payment",
        meta: {
          // title: "支付宝支付",
        },
        component: () =>
          import("../views/recharge/payment/index.vue"),
      },
      {
        path: "/agreement",
        name: "agreement",
        component: () =>
          import("../views/agreement/index.vue"),
          children: [
            {
              path: "/agreement/user",
              name: "userAgr",
              component: () => import("../views/agreement/user/index.vue"),
            },
            {
              path: "/agreement/privacy",
              name: "privacyAgr",
              component: () => import("../views/agreement/privacy/index.vue"),
            },
            {
              path: "/agreement/refund",
              name: "refundAgr",
              meta: {
                title: "退款政策",
              },
              component: () => import("../views/agreement/refund/index.vue"),
            },
            {
              path: "/agreement/copyright",
              name: "copyrightAgr",
              meta: {
                title: "版权声明",
              },
              component: () => import("../views/agreement/copyright/index.vue"),
            },
            {
              path: "/agreement/cookie",
              name: "cookieAgr",
              meta: {
                title: "Cookie政策",
              },
              component: () => import("../views/agreement/cookie/index.vue"),
            },
            {
              path: "/agreement/pay",
              name: "payAgr",
              component: () => import("../views/agreement/pay/index.vue"),
            },
          ]
      },
      
    ]
  },


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  }
});
router.beforeEach((to, from, next) => {
  // 判断是手机访问才触发阻止提示框
  if (to.meta.requiresAuth && window.innerWidth <= 768) {
        
    next(); // 阻止路由导航 // next(false)
  } else {
    next();
  }
});
export default router;
