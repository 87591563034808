<template>
    <a-tooltip placement="bottomRight" v-model:visible="tooltipVisible" trigger="hover" color="#fff"
        @visibleChange="visibleChange" overlayClassName="diy-select">
        <template #title>
            <div class="list pt-20 pb-20 pl-10 pr-10">
                <div class="list-head">
                    <template v-if="store.state.userInfo?.id">
                        <img class="avatar" :src="store.state.userInfo?.avatar" v-if="store.state.userInfo?.avatar" alt="">
                         <img class="avatar" src="../../assets/image/avatar.png" v-else alt="">
                    </template>
                    <a-button v-else style="width: 100px;" type="primary" @click="goPage('/login')">登录/注册</a-button>

                    <div class="user-info ml-10">
                        <div class="name c0 fs-15 fw-500">{{ store.state.userInfo?.realname }}</div>
                        <div v-if="store.state.userInfo?.brandTitle" class="tag pt-5 pb-5 c-00B96B fs-10 mt-5">{{
        store.state.userInfo?.brandTitle }}
                        </div>
                    </div>
                </div>
                <div class="topup mt-10">
                    <div class="unit c0" style="display: flex;align-items: flex-end;margin-right: 15px;">
                        <span class="fw-600 fs-10" style="width: max-content;">余额￥</span>
                        <span class="price fw-bold fs-20">{{ store.state.myBalance?.rechargeAmount }}</span>
                    </div>
                    <a-button type="primary" class="fs-13" size="small" @click="topup"
                        style="min-width: 57px;height: 28px;margin-left:auto">去充值</a-button>
                </div>
                <div class="tool-list mt-35 pointer">
                    <div class="list-item mb-25" v-for="(item, index) in listArr" @click="switchRouter(item.to)">
                        <img class="icon" :src="item.icon" alt="">
                        <span class="c9 ml-5 fs-16">{{ item.name }}</span>
                    </div>
                </div>
            </div>
        </template>
        <div class="avatar-box">
            <template v-if="store.state.userInfo?.id">
                        <img class="avatar" :src="store.state.userInfo?.avatar" v-if="store.state.userInfo?.avatar" alt="">
                         <img class="avatar" src="../../assets/image/avatar.png" v-else alt="">
                    </template>
            <div v-else class="goLogin-btn" @click="goPage('/login')">登录/注册</div>
            <div class="circle-wrap fromCenter ae-4 done" v-if="store.state.userInfo?.id">
                <div class="circle"></div>
                <div class="circle-1"></div>
                <div class="circle-2"></div>
                <div class="circle-3"></div>
            </div>
        </div>
    </a-tooltip>
    <a-modal v-model:visible="showConfirm" width="380px" centered title="确认退出账号？" okText="确认" cancelText="取消"
        @ok="handleOk">
    </a-modal>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted } from "vue";
import avatarListIcon1 from "@/assets/image/avatar-list-1.png";
import avatarListIcon2 from "@/assets/image/avatar-list-2.png";
import avatarListIcon3 from "@/assets/image/avatar-list-3.png";
import avatarListIcon4 from "@/assets/image/avatar-list-4.png";
import avatarListIcon5 from "@/assets/image/avatar-list-5.png";
import avatarListIcon6 from "@/assets/image/avatar-list-6.png";
import avatarListIcon7 from "@/assets/image/avatar-list-7.png";
import avatarListIcon8 from "@/assets/image/avatar-list-8.png";
import request from '@/utils/request';
import { store } from "@/store/index"
import router from "@/router";
// console.log(store.state.userInfo)
const tooltipVisible = ref<boolean>(false);
const emit = defineEmits();
const listArr = ref<{ icon: string; name: string; to: string }[]>([
    {
        icon: avatarListIcon1,
        name: '面板首页',
        to: "/my/home",
    },
    {
        icon: avatarListIcon2,
        name: '我的作品',
        to: "/my/works",
    },
    {
        icon: avatarListIcon3,
        name: '我的收益',
        to: "/my/earnings",
    },
    {
        icon: avatarListIcon4,
        name: '我的下载',
        to: "/my/download",
    },
    {
        icon: avatarListIcon8,
        name: '我的支付',
        to: "/my/payment",
    },
    {
        icon: avatarListIcon5,
        name: '我的收藏',
        to: "/my/collect",
    },
    {
        icon: avatarListIcon6,
        name: '账号设置',
        to: "/my/setting",
    },
    {
        icon: avatarListIcon7,
        name: '退出账号',
        to: "logout",
    },
])
const goPage = (url: any) => {
    router.push({
        path: url,
        query: {}
    })
}
const switchRouter = (to: string) => {
    tooltipVisible.value = false;
    if (to === "logout") {
        showConfirm.value = true;

    } else {
        router.push({ path: to })

    }

}
const handleOk = () => {
    request.post('/login/logout').then(res => {
        if (res && res.code === 200) {
            localStorage.clear();
            store.commit("setUserInfo", null)
            router.push({ name: "login" })
        }
    });
}
const topup = () => {
    tooltipVisible.value = false;
    router.push({ name: "rechargePrice" })

}
const visibleChange = (visible: boolean) => {
    if (visible && store.state.userInfo === null) {
        tooltipVisible.value = false;
    }
}
const showConfirm = ref(false);
const doScroll = () => {
    tooltipVisible.value = false
}
onMounted(() => {
    window.addEventListener('scroll', doScroll)
})

onUnmounted(() => {
    window.removeEventListener('scroll', doScroll)
}) 
</script>

<style lang="less" scoped>
:deep(.ant-avatar-image) {
    padding: 0 !important;
}

.diy-options {
    padding: 8px 6px;

    .item {
        padding: 20px 0px;
        cursor: pointer;
        display: flex;
        align-items: center;

        .icon {
            width: 24px;
            height: 24px;
            flex-shrink: 0;
            margin-right: 15px;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .title {
            font-size: 14px;
            color: #000
        }

        &:hover {
            .title {
                color: #00B96B;
            }
        }
    }

}

.list {

    .list-head {
        display: flex;

        .avatar {
            width: 47px;
            height: 47px;
            border-radius: 50%;
            flex-shrink: 0;
        }
    }

    .topup {
        padding: 10px;
        width: 100%;
        border-radius: 6px;
        background: #EEEEEE;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .tool-list {

        .list-item {
            display: flex;
            align-items: center;

            .icon {
                width: 24px;
                height: 24px;
            }
        }

        .list-item:last-of-type {
            margin-bottom: 0 !important;
        }
    }

    .user-info {
        .name {
            width: 100px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .tag {
        border-radius: 4px;
        box-sizing: border-box;
        text-align: center;
        border: 1px solid #00B96B;
        width: max-content;
        padding: 2px 6px;
    }
}

.avatar {
    width: 47px;
    height: 47px;
    border-radius: 50%;
}

.goLogin-btn {
    width: 100px;
    height: 40px;
    border-radius: 6px;
    border: 1px solid #3AB96A;
    font-size: 14px;
    color: #00B96B;
    text-align: center;
    line-height: 40px;

    &:hover {
        background-color: #00B96B;
        color: #fff;
    }
}
.avatar-box{
    position: relative;
}
.circle-wrap {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.circle-wrap .circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 2px solid #00b96c5b;
  border-radius: 300px;
  animation: breathe 5s ease-in-out infinite alternate;
  -webkit-transition: border-color .2s;
  transition: border-color .2s;
}
.circle-wrap .circle-1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  opacity: 0;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #00B96B;
  border-radius: 300px;
  animation: zoomZoom 5s ease infinite;
}
.circle-wrap .circle-2 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  opacity: 0;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #00B96B;
  border-radius: 300px;
  animation: zoomZoom 5s ease 1s infinite;
}
.circle-wrap .circle-3 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  opacity: 0;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #00B96B;
  border-radius: 300px;
  animation: zoomZoom 5s ease 2s infinite;
}
@keyframes breathe {
  from {
    transform: translate3d(-50%, -50%, 0) scale(1);
    opacity: 1;
  }
  to {
    transform: translate3d(-50%, -50%, 0) scale(1.2);
    opacity: 0.8;
  }
}
@keyframes zoomZoom {
  from {
    transform: translate3d(-50%, -50%, 0) scale(0);
    opacity: 1;
  }
  to {
    transform: translate3d(-50%, -50%, 0) scale(2);
    opacity: 0;
  }
}
@keyframes feedrotateL {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes feedrotateR {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.circle-wrap .circle {
  width: 50px;
  height: 50px;
}
.circle-wrap .circle-1 {
  width: 55px;
  height: 55px;
}
.circle-wrap .circle-2 {
  width: 55px;
  height: 55px;
}
.circle-wrap .circle-3 {
  width: 55px;
  height: 55px;
}
@media screen and (max-width: 1580px) {
    .placeholder {
        padding: 0px;
    }
}
</style>
