<template>
    <svg class="svg-icon" :style="svgStyle" aria-hidden="true">
      <use :xlink:href="iconHref"></use>
    </svg>
  </template>
  
  <script lang="ts">
  import { defineComponent, computed, PropType } from 'vue';
  
  export default defineComponent({
    name: 'SvgIcon',
    props: {
      // 图标名称
      iconName: {
        type: String as PropType<string>,
        required: true
      },
      // 图标颜色
      color: {
        type: String as PropType<string>,
        default: 'currentColor'
      },
      // 图标大小
      size: {
        type: String as PropType<string>,
        default: '1em'
      }
    },
    setup(props) {
      // 计算属性，用于生成 use 元素的 href 属性
      const iconHref = computed(() => `#icon-${props.iconName}`);
  
      // 计算 SVG 样式
      const svgStyle = computed(() => ({
        color: props.color,
        fontSize: props.size
      }));
  
      return {
        iconHref,
        svgStyle
      };
    }
  });
  </script>
  
  <style scoped>
  .svg-icon {
    width: 1em; /* 默认宽度 */
    height: 1em; /* 默认高度 */
    vertical-align: -0.15em; /* 对齐调整 */
    fill: currentColor; /* 颜色填充 */
    overflow: hidden; /* 隐藏溢出 */
  }
  </style>
  