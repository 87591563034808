import axios, { AxiosInstance } from "axios";
import { message } from 'ant-design-vue';
// import { useRouter } from 'vue-router'
import { store } from "@/store/index"
// const router = useRouter()
import router from '../router/index'
import qs from "qs";


// 创建 Axios 实例
const axiosInstance: AxiosInstance = axios.create({
  baseURL: "https://api.xiangsusheji.com/pixel/web",
});

// 创建插件
export default {
  post: async (url: string, data?: any, config?: any) => {
    try {
      const res = await axiosInstance.post(url, data, {
        headers: {
          'X-Access-Token': localStorage.getItem('token')
        }
      });
      if (res.status == 200 && res.data) {
        if (res.data.code === 200) {
          return res.data;
        } else {
          res.data.message && message.error(res.data.message)
          return false
        }
      } else {
        message.error('网络错误')
      }
    } catch (e) {
      noLogin(url, e)
    }
  },
  // get: async (url: string, data?: any, config?: any) => {
  //   const queryString = qs.stringify(data);
  //   url += `?${queryString}`;
  //   const res = await axiosInstance.get(url, {
  //     headers: {
  //       'X-Access-Token': localStorage.getItem('token')
  //     }
  //   });

  //   if(res.status == 200 && res.data){
  //     if (res.data.code === 200) {
  //       return res.data;
  //     } else {
  //       message.error(res.data.message)
  //       return false
  //     }
  //   }else{
  //     message.error('网络错误')
  //   }
  // },
  get: async (url: string, data?: any, config?: any) => {
    const queryString = qs.stringify(data);
    url += `?${queryString}`;
    try {
      const res = await axiosInstance.get(url, {
        headers: {
          'X-Access-Token': localStorage.getItem('token')
        }
      });
      // 如果返回的状态码为 401，表示未经授权，可能是用户未登录或登录凭证失效
      if (res.status === 401) {
        // 处理未经授权的情况，可能是跳转到登录页或者其他处理
        console.log('handleUnauthorizedError()');

        return false;
      }

      // 如果返回的状态码为 500，表示服务器内部错误
      if (res.status === 500) {
        // 处理服务器内部错误的情况，例如显示错误消息给用户或者其他处理
        message.error('服务器内部错误');
        return false;
      }

      // 如果返回的状态码为 200，并且返回数据有效，则返回数据
      if (res.status === 200 && res.data) {
        if (res.data.code === 200) {
          return res.data;
        } else {
          res.data.message && message.error(res.data.message);
          return false;
        }
      } else {
        message.error('网络错误');
        return false;
      }
    } catch (e) {
      // 处理网络错误或其他异常
      noLogin(url, e)
      // message.error(e);
      return false;
    }
  },
};

// 未登录的时候处理
const noLogin = (url: any, info: any) => {
  const apiList = ['/order/getUserIncome?','/user/getUserInfo?','/order/getIncomeOrderList?startDate=2024-04-05&endDate=2024-04-05&pageNo=1&pageSize=10']
  try {
    if (info.response.status == 401) {
      store.commit("setUserInfo", null)
      if (!apiList.includes(url)) {
        message.error(info.response.data.message);
        localStorage.clear();
        setTimeout(() => {
          router.push({ name: "login" })
        },500)
      }
    }
  } catch (error) {
    message.error('系统错误');
  }
}

