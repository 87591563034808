<template>
  <a-modal :visible="visible" title="阻止提示" @cancel="handleCancel">
    <p>您确定要继续吗？</p>
  </a-modal>
</template>

<script lang="ts">
import { ref } from 'vue';
import { Modal } from 'ant-design-vue';

export default {
  setup() {
    const visible = ref(false);

    const handleCancel = () => {
      visible.value = false;
    };

    return {
      visible,
      handleCancel,
    };
  },
};
</script>

<style scoped>
/* 样式可以根据需求自行添加 */
</style>
