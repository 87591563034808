<template>
    <div class="site-logo">
      <a href="/"><img src="../../assets/logo.png" alt=""></a>
    </div>
</template>

<script lang="ts" setup>
import { ref, defineComponent } from "vue";
const top = ref(0);
</script>

<style scoped lang="less"></style>
