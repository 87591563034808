<template>
  <div class="view-image" :style="`width:${width}`">
    <img ref="childImage" :src="src" alt="">
    <div class="mask" @click="goDetail">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" version="1.1"
        width="40" height="40" viewBox="0 0 40 40">
        <g>
          <g>
            <g style="opacity:0.5;">
              <ellipse cx="20" cy="20" rx="19.5" ry="19.5" fill-opacity="0" stroke-opacity="0.5" stroke="#FFFFFF"
                fill="none" stroke-width="1" />
            </g>
            <g>
              <g>
                <g></g>
              </g>
              <g>
                <g>
                  <path
                    d="M28.31855354127884,19.082909967803957C28.167853541278838,18.819179967803954,25.19125354127884,12.979259967803955,19.42673354127884,12.979259967803955C13.66217354127884,12.979259967803955,10.685706541278838,18.819179967803954,10.534998541278839,19.082909967803957C10.308938441278839,19.497359967803956,10.308938441278839,20.024839967803956,10.534998541278839,20.439279967803955C10.685706541278838,20.703029967803957,13.66218354127884,26.542959967803956,19.42673354127884,26.542959967803956C25.19125354127884,26.542959967803956,28.167753541278838,20.703029967803957,28.31845354127884,20.439279967803955C28.50685354127884,20.024839967803956,28.50685354127884,19.497359967803956,28.31845354127884,19.082909967803957C28.31845354127884,19.082909967803957,28.31855354127884,19.082909967803957,28.31855354127884,19.082909967803957ZM19.42673354127884,25.035859967803955C14.528753541278839,25.035859967803955,11.891363541278839,19.761109967803954,11.891363541278839,19.761109967803954C11.891363541278839,19.761109967803954,14.528753541278839,14.486339967803955,19.42673354127884,14.486339967803955C24.324753541278838,14.486339967803955,26.962153541278838,19.761109967803954,26.962153541278838,19.761109967803954C26.962153541278838,19.761109967803954,24.324753541278838,25.035859967803955,19.42673354127884,25.035859967803955C19.42673354127884,25.035859967803955,19.42673354127884,25.035859967803955,19.42673354127884,25.035859967803955Z"
                    fill="#FFFFFF" fill-opacity="1" />
                </g>
                <g>
                  <path
                    d="M19.426823199481966,16.74692964553833C17.768983199481966,16.74692964553833,16.412603199481964,18.10327964553833,16.412603199481964,19.76108964553833C16.412603199481964,21.41886964553833,17.768983199481966,22.77524964553833,19.426823199481966,22.77524964553833C21.084543199481963,22.77524964553833,22.440923199481965,21.41886964553833,22.440923199481965,19.76108964553833C22.440923199481965,18.10327964553833,21.084543199481963,16.74692964553833,19.426823199481966,16.74692964553833C19.426823199481966,16.74692964553833,19.426823199481966,16.74692964553833,19.426823199481966,16.74692964553833ZM19.426823199481966,21.26813964553833C18.597903199481962,21.26813964553833,17.919683199481963,20.589979645538328,17.919683199481963,19.76108964553833C17.919683199481963,18.93219964553833,18.597903199481962,18.25400964553833,19.426823199481966,18.25400964553833C20.255683199481965,18.25400964553833,20.933843199481963,18.93219964553833,20.933843199481963,19.76108964553833C20.933843199481963,20.589979645538328,20.255683199481965,21.26813964553833,19.426823199481966,21.26813964553833C19.426823199481966,21.26813964553833,19.426823199481966,21.26813964553833,19.426823199481966,21.26813964553833Z"
                    fill="#FFFFFF" fill-opacity="1" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineComponent, defineProps } from "vue";
import img1 from '@/assets/image/test.png'
import { useRouter } from 'vue-router'
interface Props {
  src: string;
  width: string;
  href: string;
  id: string;
  target: string;
}
const props = withDefaults(defineProps<Props>(), {
  width: "auto",
  id: '',
  href: '',
  target: ''
})
const router = useRouter()

const goDetail = () => {
  if (props.href) {
    window.open(props.href)
  } else {
    if (props.target) {
      // 使用 router.resolve 解析带参数的目标路由的完整 URL
      const routeData = router.resolve({
        name: 'details', // 假设这是你的目标路由名
        params: {id: props.id }, // 路径参数
        query: { id: props.id }  // 查询参数
      });
      // 在新标签页中打开这个URL
      window.open(routeData.href, '_blank');
    } else {
      router.push({
        path: '/details',
        query: { id: props.id }
      })
    }
  }
}
</script>

<style scoped lang="less">
.view-image {
  // max-width: 100%;
  // max-height: 100%;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  background-color: #F7F8FA;

  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    opacity: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: #fff;
    transition: opacity 0.5s ease;
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover {
    .mask {
      opacity: 1
    }
  }
}
</style>
