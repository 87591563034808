import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import {store,key} from "./store";
import Antd from 'ant-design-vue';
import svgIcon from './components/svgIcon.vue'
import UUID from 'vue3-uuid'
import MobileWarningModal from '@/components/MobileWarningModal.vue';
const app = createApp(App);

app.component('MobileWarningModal', MobileWarningModal);
// 导入 SVG 文件
const requireAll = (requireContext: __WebpackModuleApi.RequireContext) => requireContext.keys().map(requireContext);
const req = require.context('./assets/svg', false, /\.svg$/);
requireAll(req);
import './main.less';
createApp(App).use(store,key).use(router).use(Antd).use(UUID).component('svg-icon', svgIcon).mount("#app");
