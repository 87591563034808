<template>
  <div class="page-main">
    <div class="index_banner">
      <indexBanner :bannerArr="bannerArr" />
    </div>
    <div class="main-container">
      <div class="max-section section bb-adver mb-100">
        <a-row :gutter="{ xs: 10, sm: 20, lg: 30 }">
          <a-col :xs="{ span: 12 }" :lg="{ span: 6 }" v-for="item in fourAgArr">
            <div class="item"><image-view :src="item.pictureUrl" :href="item.linkUrl"></image-view></div>
          </a-col>
        </a-row>
      </div>
      <div class="page-title fs-40">近日更新</div>
      <div class="page-title-des fs-24 c9"><span class="c0 fw-500">小步快跑，稳定更新，</span>新鲜滚辣烫的海外素材，一手海外高端素材触手可及</div>
      <div class="translucent flow-layout mb-100">
        <a-spin :spinning="recentLoading">
          <div style="height: 300px;" v-if="recentLoading"></div>
          <infiniteScroll :imageList="recentrr" />
        </a-spin>
      </div>
      <blockquote v-for="item in allProductArr">
        <div class="page-title fs-40">{{ item.title }}</div>
        <div class="page-title-des fs-24 c9" v-if="item.subTitle" v-html="highlightedText(JSON.parse(item.subTitle).key,JSON.parse(item.subTitle).highlight)"></div>
        <div class="max-section section mb-40 mt-70">
          <a-row :gutter="[{ xs: 10, lg: 40 }, { xs: 10, lg: 40 }]" class="mb-40">
            <a-col :xs="{ span: 24 }" :lg="{ span: 12 }" v-for="(pro, index) in item.baseItemInfoList"
              v-show="index < 2">
              <div class="works-item">
                <div class="img" style="aspect-ratio: 3/2;">
                  <image-view style="height: 100%;" :src="pro.coverUrls" :id="pro.id"></image-view>
                </div>
                <div class="content">
                  <div class="info">
                    <div class="title fs-20">{{ pro.name }}</div>
                    <div class="author">
                      <img class="author-photo pointer" :src="pro.baseUserInfo.avatar" alt=""
                        @click="goPage('/stylist', { id: pro.baseUserInfo.id })">
                      <span class="name fs-14 c9" @click="goPage('/stylist', { id: pro.baseUserInfo.id })">{{
        pro.baseUserInfo.realname }}</span>
                      <img class="sjx" src="../../assets/image/sjx.png" alt="">
                      <span class="fs-14 ml-10 type-name"
                        @click="goPage('/filtering', { id: pro.itemCategoryInfo.id, pid: item.categoryId })">{{
        pro.itemCategoryInfo.name }}</span>
                    </div>
                  </div>
                  <div class="oper">
                    <div class="jinbi">
                      <img src="../../assets/image/jinbi.png" alt="">
                      <span class="fs-20 fw-500 pirce" v-if="pro.isFree === 0"><i>¥</i>{{ pro.price }}</span>
                      <span class="fs-20 fw-500 pirce c-00B96B" v-else>免费</span>
                    </div>
                    <div class="renzhen">
                      <img src="../../assets/image/renzhen.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[{ xs: 10, lg: 40 }, { xs: 10, lg: 40 }]">
            <a-col :xs="{ span: 12 }" :lg="{ span: 6 }" v-for="(pro, index) in item.baseItemInfoList"
              v-show="index > 1">
              <div class="works-item">
                <div class="img" style="aspect-ratio: 3/2;">
                  <image-view style="height: 100%;" :src="pro.coverUrls" :id="pro.id"></image-view>
                </div>
                <div class="content">
                  <div class="info">
                    <div class="title fs-20">{{ pro.name }}</div>
                    <div class="author">
                      <img class="author-photo" :src="pro.baseUserInfo.avatar" alt="">
                      <span class="name fs-14 c9" @click="goPage('/stylist', { id: pro.baseUserInfo.id })">{{
        pro.baseUserInfo.realname }}</span>
                      <img class="sjx" src="../../assets/image/sjx.png" alt="">
                      <span class="fs-14 ml-10 type-name"
                        @click="goPage('/filtering', { id: pro.itemCategoryInfo.id, pid: item.categoryId })">{{
        pro.itemCategoryInfo.name }}</span>
                    </div>
                  </div>
                  <div class="oper">
                    <div class="jinbi">
                      <img src="../../assets/image/jinbi.png" alt="">
                      <span class="fs-20 fw-500 pirce" v-if="pro.isFree === 0"><i>¥</i>{{ pro.price }}</span>
                      <span class="fs-20 fw-500 pirce c-00B96B" v-else>免费</span>
                    </div>
                    <div class="renzhen">
                      <img src="../../assets/image/renzhen.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
        <div class="pt-50 pb-100 tlc">
          <a-button type="primary" @click="goPage('/filtering', { pid: item.categoryId })">查看更多</a-button>
        </div>
      </blockquote>
      <!-- <div class="pt-10 pb-100 tlc" v-if="allProductArr.length > initialDisplayCount" @click="toggleDisplay">
        <a-button type="primary"> {{ isExpanded ? '收起更多' : '查看更多' }}</a-button>
      </div>
      <div v-else style="height: 50px;"></div> -->
      <div class="page-title fs-40">加入我们</div>
      <div class="page-title-des fs-24 c9"><span class="c0 fw-500">500000+设计师已加入像素设计，</span>800+海内外设计师在线为您提供优质服务</div>
      <div class="translucent designer-avatar mt-70 mb-70">
        <div class="item" v-for="item, index in avatarArr" :key="index" @click="goPage('/stylist', { id: item.id })">
          <img :src="item.avatar" alt="">
        </div>
      </div>
    </div>
    <div class="mask" v-if="modelAgShow && isClosedToday">
      <div class="model-ad">
        <img class="close" src="../../assets/image/close-bg.png" alt="" @click="closeAdvertisement">
        <img class="ad-img" :src="modelAgArr[0].pictureUrl" alt="" @click="goAd(modelAgArr[0].linkUrl)">
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import indexBanner from '@/components/home/index-banner/index.vue'
import imageView from '@/components/image-view/index.vue'
import infiniteScroll from "@/components/infiniteScroll/index.vue"
import request from "../../utils/request";
import { useRouter } from 'vue-router'
const router = useRouter()
// 获取广告
const bannerArr = ref<any[]>([])
request.get("/home/getAdvList", { type: 6 }).then((res: any) => {
  bannerArr.value = res.result
})
const fourAgArr = ref<any[]>([])
request.get("/home/getAdvList", { type: 2 }).then((res: any) => {
  fourAgArr.value = res.result
})

// 首页弹框
const modelAgArr = ref<any[]>([])
const modelAgShow = ref<Boolean>(false)
request.get("/home/getAdvList", { type: 4 }).then((res: any) => {
  if (res && res.code == 200) {
    modelAgArr.value = res.result
    if (res.result && res.result.length > 0) {
      modelAgShow.value = true
    }
  }
})
const isClosedToday = ref(false);

const checkAdvertisementStatus = () => {
  const currentDate = new Date().toLocaleDateString();
  const closedToday = localStorage.getItem('advertisementClosedOn') === currentDate;
  isClosedToday.value = !closedToday;
};
const closeAdvertisement = () => {
  modelAgShow.value = false
  const currentDate = new Date().toLocaleDateString();
  localStorage.setItem('advertisementClosedOn', currentDate);
  isClosedToday.value = false;
};
// 近日更新列表
let recentrr = ref<any[]>([])
const recentLoading = ref<Boolean>(false)
const getRecentList = () => {
  recentLoading.value = true
  request.get("/home/getBaseItemInfoListRecently").then((res: any) => {
    if (res && res.code == 200) {
      recentrr.value = res.result
    }
    recentLoading.value = false
  })
}
getRecentList()
// 商品配置
const allProductArr = ref<any[]>([])
request.get("/home/getHomeItemConfig").then((res: any) => {
  allProductArr.value = res.result
})
// 初始显示数量
const initialDisplayCount = 3;
// 当前显示数量
const displayCount = ref(initialDisplayCount);

// 计算当前应显示的项目
const displayedItems = computed(() => {
  return allProductArr.value.slice(0, displayCount.value);
});
// 是否展开状态
const isExpanded = computed(() => {
  return displayCount.value > initialDisplayCount;
});

// 切换显示状态
const toggleDisplay = () => {
  if (isExpanded.value) {
    // 如果已展开，则收起
    displayCount.value = initialDisplayCount;
  } else {
    // 显示更多，增加显示数量，或者全部显示
    displayCount.value += initialDisplayCount;
    if (displayCount.value >= allProductArr.value.length) {
      displayCount.value = allProductArr.value.length; // 防止超出
    }
  }
};
// 加入我们
const avatarArr = ref<any[]>([])
request.get("/home/getJoinUserList").then((res: any) => {
  avatarArr.value = res.result
})
//作品近日更新列表
// request.get("/home/getBaseItemInfoListRecently").then((res: any) => {
// })

const highlightedText = (text:any,highlightText:any) => {
  if (!highlightText) return text; // 如果没有指定高亮文本，返回原始文本
  const regex = new RegExp(`(${highlightText})`, 'gi');
  return text.replace(regex, `<span class='c0 fw-500'>$1</span>`);
}

const goPage = (url: any, query: any) => {
  router.push({
    path: url,
    query: query
  })
}
const goAd = (url: any) => {
  window.open(url)
}
onMounted(() => {
  checkAdvertisementStatus();
});
</script>

<style lang="less" scoped>
.main-container {
  position: relative;
  z-index: 2;
  margin-top: -140px;
}

.home-main {
  position: relative;
  margin-top: -100px;
  z-index: 1;
}

.bb-adver {
  .item {
    border-radius: 10px;
    overflow: hidden;

    img {
      max-width: 100%;
    }
  }
}

.flow-layout {
  margin-top: 40px;
  width: 100%;
  overflow: hidden;
  height: 650px;
}

.works-item {
  background: #FAFAFB;
  border-radius: 20px;

  .img {
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;

    img {
      max-width: 100%;
    }
  }

  .content {
    display: flex;
    align-items: center;
    padding: 10px 15px;

    .info {
      .title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; // 1代表行数
        overflow: hidden;
        margin-bottom: 10px;
        cursor: pointer;
        color: #000;

        &:hover {
          color: #666;
        }
      }

      .author {
        display: flex;
        align-items: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1; // 1代表行数
        overflow: hidden;

        img {
          margin-right: 10px;
        }

        .name {
          margin-right: 10px;
          cursor: pointer;

          &:hover {
            color: #666;
          }
        }

        .sjx {
          transform: rotate(-90deg);
          margin-top: -3px;
        }

        .author-photo {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          vertical-align: top;
          overflow: hidden;
        }

        .type-name {
          color: #999;
          cursor: pointer;

          &:hover {
            color: #666;
          }
        }
      }
    }

    .oper {
      margin-left: auto;
      width: 60px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .jinbi {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        img {
          margin-right: 5px;
        }

        i {
          font-style: normal;
          font-size: 12px;
          padding-right: 1px;
        }
      }
    }
  }
}

.designer-avatar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .item {
    margin: 5px 10px;
    width: 84px;
    height: 84px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    border: 2px solid #fff;
    cursor: pointer;
    transition: box-shadow .2s;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
      filter: grayscale(100%);
    }

    &:hover {
      border-color: #00B96B;
      z-index: 2;

      img {
        -webkit-filter: grayscale(0%);
        -moz-filter: grayscale(0%);
        -ms-filter: grayscale(0%);
        -o-filter: grayscale(0%);
        filter: grayscale(0%);
      }
    }
  }
}



.mask {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .model-ad {
    position: relative;

    .ad-img {
      max-width: 560px;
      cursor: pointer;
    }

    .close {
      width: 24px;
      height: 24px;
      position: absolute;
      top: -30px;
      right: -30px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1024px) {

  .bb-adver {
    .item {
      margin-bottom: 30px;
    }
  }

  .flow-layout {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1024px) {

  .bb-adver {
    .item {
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 1580px) {
  .main-container {
    margin-top: -60px;
  }
}
</style>