<template>
    <div class="scroll-infinite" ref="content" v-if="loading" @mouseleave.self="mouseLeaveScroll"
        @mouseenter="setImageEvent">
        <div ref="scrollRef" class="scroll-warp" :style="`display: flex;width: max-content;flex-wrap: nowrap;`">
            <waterfall style="margin-right: 20px;" v-for="(item, index) in list" :key="index" :images="item"
                :column-count="1" :width="320"></waterfall>
        </div>
    </div>
</template>
<script lang="ts" setup>
import waterfall from '@/components/new-waterfall/index.vue'
import { onMounted, ref, watch, onUnmounted } from "vue"
const list = ref<any>([])
// 参数类型
interface Props {
    imageList?: any[],
}
const props = withDefaults(defineProps<Props>(), {
    imageList: () => []
})
// 开始滚动
let translateX = ref<number>(0);
// 滚动速度
let speed = ref<number>(2);

// 动画是否在播放
const isPlaying = ref<boolean>(false);
let intervalID = ref<any>(null)
const animationId = ref<number>(0);
const scrollRef = ref()
// const scrollStart = async (): void => {
//     try {
//         translateX.value -= speed.value
//         scrollRef.value.style.transform = `translateX(${translateX.value}px)`;
//         animationId.value = requestAnimationFrame(scrollStart);
//         isPlaying.value = true;
//         let rightwidth = await getRight()
//         if ((Number(rightwidth) + 500) > 0) {
//             list.value.push(...list.value)
//         }
//     } catch (error) {
//         return
//     }
// }
const isDuplicating = ref(false)
const scrollStart = async () => {
    try {
        translateX.value -= speed.value;
        scrollRef.value.style.transform = `translateX(${translateX.value}px)`;
        animationId.value = requestAnimationFrame(scrollStart);
        isPlaying.value = true;

        const rightWidth = await getRight();
        if (Number(rightWidth) > -500 && !isDuplicating.value) { // 使用标志避免重复添加
            isDuplicating.value = true; // 设置正在复制的标志
            list.value = [...list.value, ...list.value]; // 复制列表
            isDuplicating.value = false; // 重置标志
        }
    } catch (error) {

    }
};
// 鼠标移入
const mouseEnterScroll = (): void => {
    cancelAnimationFrame(animationId.value)
    isPlaying.value = false;
}
// 鼠标移开
const mouseLeaveScroll = (): void => {
    if (isPlaying.value) return;
    scrollStart();
    isPlaying.value = true
}
const setImageEvent = (e: MouseEvent): void => {
    if (!e.target) return;
    e.stopPropagation();
    mouseEnterScroll()
}
// 获取div距离右边的距离
const getRight = () => {
    return new Promise(function (resolve, reject) {
        setTimeout(() => {
            try {
                const rect = scrollRef.value.getBoundingClientRect();
                const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
                const distanceFromRight = viewportWidth - rect.right;
                resolve(distanceFromRight);
            } catch (error) {

            }
        }, 1000);
    });

}
const count = ref<number>(5)
const width = ref<number>(1400)
const loading = ref(false)
// 组装数据n个为一组
const chunkArray = (array: any, chunkSize: any) => {
    debugger
    const result = [];
    // 需要补的条数
    let supplementNum = 0
    // 假如不是5的倍数
    if (array.length % chunkSize !== 0) {
        // supplementNum = Math.ceil(array.length/5)*5 - array.length
        // array.push(...array.slice(0,supplementNum))
        for (let j = 0; j < chunkSize; j++) {
            array.push(...array)
        }
    }
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
}
// const chunkArray = (array: any, chunkSize: any) => {
//     const result = [];
//     for (let i = 0; i < array.length; i += chunkSize) {
//         result.push(array.slice(i, i + chunkSize));
//     }
//     return result;
// };

const listLen = ref<number>(0)
onMounted(() => {
    watch(() => props.imageList, (nv, ov) => {
        list.value = chunkArray(nv, 5)
        listLen.value = list.value.length
        if (nv.length > 5) {
            count.value = parseInt(props.imageList.length / 2)
            width.value = count.value * 320
        }
        loading.value = true

        setTimeout(() => {
            scrollStart()
        }, 3000)
    })
})
onUnmounted(() => {
    mouseEnterScroll()
})
</script>
<style lang="less" scoped>
.scroll-infinite {
    width: 100%;
    overflow-x: hidden;

    .scroll-inner {
        padding: 100px 0px;
        display: flex;
        align-items: flex-start;
        // align-items: center;
    }
}

.scroll-warp {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

@media screen and (max-width: 1024px) {
    .scroll-infinite {
        .scroll-inner {
            padding: 20px 0px;

        }
    }

}
</style>
