<template>
  <div class="main-layout">
    <Header />
    <main style="min-height:calc(100vh - 214px)">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </main>
    <Footer v-if='!hideRoutes.includes(route.path)' />
    <div v-else style="height: 100px;"></div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import Header from "@/components/Header.vue"; // 头部
import Footer from "@/components/footer.vue"; // 尾部
import { useRoute } from 'vue-router'
const route = useRoute()
const hideRoutes = ref(['/my/home', '/my/works', '/my/earnings', '/my/payment', '/my/download', '/my/collect', '/my/setting', '/publish', '/my/nojoin','/search/mobile'])
</script>

<style scoped>
.main-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  padding: 20px;
}
</style>
