<template>
  <a-tooltip placement="bottomLeft" v-model:visible="tooltipVisible" trigger="click" color="#fff"
    overlayClassName="diy-select" :getPopupContainer="(trigger) => trigger.parentNode">
    <template #title>
      <div class="diy-options">
        <div class="item" v-for="item in categoryOptions" @click="change(item)">
          <div class="icon">
            <img v-show="item.icon" style="width: 24px;" :src="item.icon" :alt="item.name">
          </div>
          <div class="title">{{ item.name }}</div>
        </div>
      </div>
    </template>
    <div class="placeholder">
      <span>{{ store.state.categoryType.name || valueName || '所有素材' }}</span>
      <img src="../../assets/image/sjx.png" alt="">
    </div>
  </a-tooltip>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted, Ref, watch } from "vue";
import request from "@/utils/request";
const tooltipVisible: Ref<boolean> = ref(false);
import { store } from "@/store"
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()
const emit = defineEmits();
const { modelValue = '' } = defineProps(['modelValue']);
const categoryOptions = ref<any>([
  {
    id: '',
    icon: 'https://pubimg-wfile.xiangsusheji.com/2024/04/05/22/e882e016-fcc7-4967-80ab-85bfa95e86bf.png',
    name: '所有素材'
  }
])

const valueName = ref<any>('')
interface categoryOptions {
  value: string | number;
  label?: any;
  disabled?: boolean;
  children?: [];
}
request.get("/item/getItemCategoryList").then(res => {
  if (res && res.code === 200) {
    store.commit("setCategory", res.result)
    categoryOptions.value.push(...res.result)
    if (route.query.pid) {
      store.commit("setCategoryType", {
        value: route.query.pid,
        name: getTopTypeName(route.query.pid)
      })
      valueName.value = getTopTypeName(route.query.pid)
    } else {
      store.commit("setCategoryType", {
        value: '',
        name: '所有素材'
      })
    }
  }
})
watch(
  () => route.query,
  (newParams, oldParams) => {
    if (newParams.pid) {
      store.commit("setCategoryType", {
        value: newParams.pid,
        name: getTopTypeName(newParams.pid)
      })
    }
  },
  {
    deep: true, // 深度监听
    flush: 'post' // 在侦听器回调中访问被 Vue 更新之后的 DOM
  },
);
// 获取选中的一级分类名称
const getTopTypeName = (id: any) => {
  if (!id) return ''
  return categoryOptions.value.filter((row: any) => {
    return row.id == id
  })[0]?.name
}

const change = (row: any) => {
  valueName.value = row.name
  tooltipVisible.value = false
  router.push({
    path: '/filtering',
    query: {
      ...route.query,
      id: '',
      pid: row.id
    }
  })
  store.commit("setCategoryType", {
    value: row.id,
    name: row.name
  })
  emit('update:modelValue', row.id);
}
// const doScroll = () => {
//   tooltipVisible.value = false
// }
// onMounted(() => {
//   window.addEventListener('scroll', doScroll)
// })

// onUnmounted(() => {
//   window.removeEventListener('scroll', doScroll)
// }) 
</script>

<style lang="less">
.placeholder {
  font-size: 15px;
  color: #666;
  width: max-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
  // background: #F5F7FB;
  padding: 0px 19px;
  border-radius: 6px;
  cursor: pointer;

  span {
    padding-right: 13px;
  }
}

.diy-select {
  .ant-tooltip-inner {
    border: 1px solid #E9E9EB;
  }

  .ant-tooltip-arrow {
    display: none
  }
}

.ant-tooltip-placement-bottomLeft {
  padding-top: 20px !important;
}

.diy-options {
  padding: 8px 6px;

  .item {
    padding: 20px 0px;
    cursor: pointer;
    display: flex;
    align-items: center;

    .icon {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      margin-right: 15px;
      display: flex;
      overflow: hidden;

      img {
        width: 24px;
        height: 24px;
        filter: drop-shadow(24px 0px #00B96B);
        transition:  0.3s ease;
      }
    }

    &:hover {
      .icon {
        img {
          margin-left: -24px;
        }
      }
    }

    .title {
      font-size: 14px;
      color: #000
    }

    &:hover {
      .title {
        color: #00B96B;
      }
    }
  }

}
</style>
